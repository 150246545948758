import * as types from "./mutation-types";

export default {
  [types.LISTAR_MOEDAS]: (state, { moedas }) => {
    state.staMoedas = moedas;
  },

  [types.SETAR_ERRO]: (state, { erro }) => {
    state.staErro = erro;
  },
};
